<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ProductService from '@/services/product'
import StoreService from '@/services/store'
import Multiselect from 'vue-multiselect';

export default {
  page: {
    title: "Produtos",
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Produtos",
      loading: false,
      products: [],

      brands: [],
      categories: [],
      searchValue: '',
      searchBrands: '',
      searchCategories: '',
      showMoreFilters: false,

      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      productToRemove: '',
      fields: [
        { key: 'image', sortable: false, label: 'Produto' },
        { key: 'product.barcode', sortable: true, label: 'Cód. Barras' },
        { key: 'code', sortable: true, label: 'Cód. SKU' },
        { key: 'description', sortable: true, label: 'Descrição' },
        { key: 'brandDescription', sortable: true, label: 'Marca' },
        { key: 'stock', sortable: true, label: 'Estoque' },
        { key: 'price', sortable: true, label: 'Preço', formatter: (value) => {
          return (value || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        } },
      ],
    }
  },
  computed: {
    rows() {
      return this.products.length;
    }
  },
  methods: {
    loadProducts(showLoading = true, page = 1, term = '') {
      let loader;
      if (showLoading) {
        loader = this.$loading.show();
      }

      const params = {
        term,
        brands: (this.searchBrands || []).map(item => item._id),
        categories: (this.searchCategories || []).map(item => item._id),
        store: this.$route.params.store
      }

      StoreService.getStoreAdminProducts(page, params)
        .then(res => {
          if (loader) {
            loader.hide();
          }

          this.products = res.data.products;
          this.count = res.data.count;
          this.currentCount = res.data.currentCount;
          this.currentPage = res.data.currentPage;
          this.totalPages = res.data.totalPages;
          this.perPage = res.data.perPage;
          this.startIndex = res.data.startIndex;
          this.endIndex = res.data.endIndex;

          if (res.data.storeName) {
            this.title = `Produtos - ${res.data.storeName}`;
          }
        })
      .catch(() => {
        if (loader) {
          loader.hide();
        }
      })
      
      this.totalRows = this.products.length;

      const requests = [
        ProductService.getStoreCategories(),
        ProductService.getStoreBrands(),
      ]

      Promise.all(requests).then(res => {
        this.categories = res[0].data;
        this.brands = res[1].data;
      })
    },
    onPageChange() {
      this.loadProducts(true, this.currentPage, this.searchValue);
    },
    search() {
      this.loadProducts(true, 1, this.searchValue);
    },
    removeProduct() {
      this.$bvModal.hide('modal-remove');      

      ProductService.removeStoreProduct(this.productToRemove._id).then(() => {
        this.loadProducts(false);
        this.$toast.open('Produto excluído com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao excluir o produto');
      })
    },
    exportData() {
      let loader = this.$loading.show();

      ProductService.exportStoreProducts({ term: this.searchValue }).then(res => {
        loader.hide();

        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'produtos-loja.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch(() => {
        loader.hide();
      })
    }
  },
  mounted() {
    this.loadProducts();
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-9 col-lg-9">
                <div class="position-relative search-input search-box">
                  <form @submit.prevent="search" class="align-items-center direction-column">
                    <div class="row mb-2 full">
                      <div class="col col-12 inline">
                        <input type="text" class="form-control" v-model="searchValue" placeholder="Pesquisar por código ou descrição..." />

                        <div class="buttons">
                          <b-button variant="link" v-if="!showMoreFilters" @click="showMoreFilters = true" class="ml-2">Mais Filtros</b-button>
                          <b-button variant="link" v-if="showMoreFilters" @click="showMoreFilters = false" class="ml-2">Esconder Filtros</b-button>
                        </div>
                      </div>
                    </div>

                    <!-- <input type="text" class="form-control" v-model="searchValue" placeholder="Pesquisar por código ou descrição..." /> -->

                    <!-- <button type="submit" class="btn btn-rounded btn-outline-primary ml-2">
                      Pesquisar
                    </button>

                    <b-button variant="link primary" class="ml-2" @click="exportData">Exportar</b-button> -->

                    <div class="row mb-2 full more-filters" v-if="showMoreFilters">
                      <div class="col col-6">
                        <label class="control-label m-0">Marcas</label>
                        <multiselect
                          v-model="searchBrands"
                          :options="brands"
                          :multiple="true"
                          placeholder="Selecione uma opção"
                          label="description"
                          track-by="_id"
                          selectLabel="Enter para selecionar"
                          selectedLabel="Selecionado"
                          deselectLabel="Enter para remover"
                        ></multiselect>
                      </div>

                      <div class="col col-6">
                        <label class="control-label m-0">Categorias</label>
                        <multiselect
                          v-model="searchCategories"
                          :options="categories"
                          :multiple="true"
                          placeholder="Selecione uma opção"
                          label="description"
                          track-by="_id"
                          selectLabel="Enter para selecionar"
                          selectedLabel="Selecionado"
                          deselectLabel="Enter para remover"
                        ></multiselect>
                      </div>
                    </div>

                    <div class="row mb-2 mt-2 full">
                      <div class="col col-12">
                        <button type="submit" class="btn btn-rounded btn-primary button-large">
                          Pesquisar
                        </button>

                        <!-- <b-button variant="link primary" class="ml-2" @click="exportData">Exportar</b-button> -->
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <!-- Table data -->
            <Product :products="products" :loadProducts="this.loadProducts"/>

            <div class="table-responsive mb-0 align-middle">
              <b-table
                align="center"
                show-empty
                emptyText="Nenhum produto para exibir"
                :items="products"
                :fields="fields"
                responsive="sm"
                head-variant="light"
              >
                <template v-slot:cell(image)="row">
                  <router-link :to="'/store/products/detail/' + row.item._id">
                    <img
                      v-bind:src="row.item.image"
                      alt="product-img"
                      title="product-img"
                      class="avatar-md"
                    />
                  </router-link>
                </template>

                <template v-slot:cell(description)="row">
                  {{row.item.product.description}}
                  <p v-if="row.item.product.variationDescription" class="text-muted mb-0">
                    {{row.item.variationType}}: {{row.item.product.variationDescription}}
                  </p>
                </template>

                <template v-slot:cell(actions)="row">
                  <b-dropdown class="card-drop" variant="white" dropleft toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>
                    <router-link :to="'/store/products/detail/' + row.item._id">
                      <b-dropdown-item href="javascript: void(0);">
                        <i class="fas fa-edit text-warning mr-2 mt-2"></i>
                        Editar
                      </b-dropdown-item>
                    </router-link>
                    <b-dropdown-item v-b-modal.modal-remove @click="productToRemove = row.item">
                      <i class="fas fa-trash-alt text-danger mr-2 mt-2"></i>
                      Excluir
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </div>


            <div class="row justify-content-md-between align-items-md-center">
              <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>
              <!-- end col-->
              <div class="col-xl-5">
                <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                  <b-pagination
                    v-model="currentPage" 
                    :total-rows="count"
                    :per-page="perPage"
                    @input="onPageChange"
                  ></b-pagination>
                </div>
              </div>
              <!-- end col-->
            </div>

            <!-- <b-pagination
              v-model="defaultvalue"
              :total-rows="50"
              :per-page="10"
              aria-controls="my-table"
            ></b-pagination> -->
            
            <!-- <ul class="pagination pagination-rounded justify-content-end mb-2">
              <li class="page-item disabled">
                <a class="page-link" href="javascript: void(0);" aria-label="Previous">
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript: void(0);">1</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">4</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">5</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);" aria-label="Next">
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul> -->
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    
    <b-modal
      id="modal-remove"
      title="Excluir Produto?"
      title-class="font-18"
      centered
    >
      <p>Confirma a exclusão do produto?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove')">Cancelar</b-btn>
        <b-btn variant="primary" @click="removeProduct">Excluir</b-btn>
      </div>
    </b-modal>
    
  </Layout>
</template>

<style lang="scss">
  .search-box form {
    display: flex;
    flex-direction: row;
  }

  .search-box .form-control {
    padding-left: 20px;
  }

  .input-search {
    width: 90%;
  }

  .table td {
    vertical-align: middle;
  }

  .search-box form.direction-column {
    flex-direction: column;
  }

  form .row.full {
    width: 100%;
  }

  .button-large {
    width: 150px;
  }

  .more-filters .multiselect__tags {
    border-radius: 30px;
  }

  .inline {
    display: flex;  
  }

  .inline .buttons {
    flex-shrink: 0;
  } 
</style>